<template>
  <div class="row">
    <div class="col-md-12">
      <h4 class="title">Persyaratan Peserta</h4>
    </div>
    <div class="col-md-12 card">
      <!-- <div class="card-header">
        <div class="category">Extended tables</div>
      </div> -->
      <div class="card-body row">
        <b-overlay :show="isLoad" rounded="sm">
          <b-row>
            <b-col xxs="12">
              <b-table-simple hover small caption-top responsive bordered striped>
                <b-thead head-variant="dark">
                  <b-tr > 
                    <b-th>No</b-th>
                    <b-th width="40%">Syarat</b-th>
                    <b-th width="15%">Upload</b-th>
                    <b-th width="15%">Data</b-th>
                    <b-th width="10%">Verifikasi Berkas</b-th>
                    <b-th width="20%">Keterangan</b-th>
                  </b-tr>
                </b-thead>
                <b-tbody>
                  <b-tr >
                    <b-th >1</b-th>
                    <b-th >
                      Surat lamaran yang dibubuhi meterai Rp.10.000 <font color="red">(tipe file : pdf, max : 500 Kb)</font>
                    </b-th>
                    <b-th >
                      <b-form-file v-model="fileUpload"  v-on:input="uploadData('1')"  plain accept="application/pdf" v-if="suratLamaran.verifikasi != 'Valid'"></b-form-file>
                    </b-th>
                    <b-th >
                      <b-button v-if="suratLamaran.file != '' && suratLamaran.file != null " type="button" variant="primary" class="mt-4" @click="lihat(1)">Lihat Data</b-button>
                    </b-th>
                    <b-th >
                      {{ suratLamaran.verifikasi }}
                    </b-th>
                    <b-th >
                      {{ suratLamaran.keterangan }}
                    </b-th>
                  </b-tr>
                  <b-tr >
                    <b-th >2</b-th>
                    <b-th >
                      Ijazah Asli yang dipersyaratkan   <font color="red">(tipe file : pdf, max : 500 Kb)</font>
                    </b-th>
                    <b-th >
                      <b-form-file v-model="fileUpload"  v-on:input="uploadData('2')"  plain accept="application/pdf" v-if="ijazah.verifikasi != 'Valid'"></b-form-file>
                    </b-th>
                    <b-th >
                      <b-button v-if="ijazah.file != '' && ijazah.file != null " type="button" variant="primary" class="mt-4" @click="lihat(2)">Lihat Data</b-button>
                    </b-th>
                    <b-th >
                      {{ ijazah.verifikasi }}
                    </b-th>
                    <b-th >
                      {{ ijazah.keterangan }}
                    </b-th>
                  </b-tr>
                  <b-tr >
                    <b-th >3</b-th>
                    <b-th >
                      Surat Keputusan pengangkatan dalam jabatan terakhir   <font color="red">(tipe file : pdf, max : 500 Kb)</font>
                    </b-th>
                    <b-th >
                      <b-form-file v-model="fileUpload"  v-on:input="uploadData('3')"  plain accept="application/pdf" v-if="sk_pengangkatan_akhir.verifikasi != 'Valid'"></b-form-file>
                    </b-th>
                    <b-th >
                      <b-button v-if="sk_pengangkatan_akhir.file != '' && sk_pengangkatan_akhir.file != null " type="button" variant="primary" class="mt-4" @click="lihat(3)">Lihat Data</b-button>
                    </b-th>
                    <b-th >
                      {{ sk_pengangkatan_akhir.verifikasi }}
                    </b-th>
                    <b-th >
                      {{ sk_pengangkatan_akhir.keterangan }}
                    </b-th>
                  </b-tr>
                  <b-tr >
                    <b-th >4</b-th>
                    <b-th >
                      Surat keterangan memiliki rekam jejak jabatan, integritas, dan moralitas yang ditandatangani oleh <b>Pejabat Pembina Kepegawaian atau Pejabat yang Berwenang</b> <font color="red">(tipe file : pdf, max : 500 Kb)</font>
                    </b-th>
                    <b-th >
                      <b-form-file v-model="fileUpload"  v-on:input="uploadData('4')"  plain accept="application/pdf" v-if="suker_rekam_jejak.verifikasi != 'Valid'"></b-form-file>
                    </b-th>
                    <b-th >
                      <b-button v-if="suker_rekam_jejak.file != '' && suker_rekam_jejak.file != null " type="button" variant="primary" class="mt-4" @click="lihat(4)">Lihat Data</b-button>
                    </b-th>
                    <b-th >
                      {{ suker_rekam_jejak.verifikasi }}
                    </b-th>
                    <b-th >
                      {{ suker_rekam_jejak.keterangan }}
                    </b-th>
                  </b-tr>
                  <b-tr >
                    <b-th >5</b-th>
                    <b-th >
                      Penilaian Prestasi Kerja 2 (dua) tahun terakhir setiap unsur minimal bernilai <b>baik</b>, tahun 2020 dan tahun 2021 <font color="red">(tipe file : pdf, max : 1500 Kb)</font>
                    </b-th>
                    <b-th >
                      <b-form-file v-model="fileUpload"  v-on:input="uploadData('5')"  plain accept="application/pdf" v-if="penilaian_prestasi.verifikasi != 'Valid'"></b-form-file>
                    </b-th>
                    <b-th >
                      <b-button v-if="penilaian_prestasi.file != '' && penilaian_prestasi.file != null " type="button" variant="primary" class="mt-4" @click="lihat(5)">Lihat Data</b-button>
                    </b-th>
                    <b-th >
                      {{ penilaian_prestasi.verifikasi }}
                    </b-th>
                    <b-th >
                      {{ penilaian_prestasi.keterangan }}
                    </b-th>
                  </b-tr>
                  <b-tr >
                    <b-th >6</b-th>
                    <b-th >
                      Tanda bukti penyerahan LHKPN/LHKASN tahun 2021 <font color="red">(tipe file : pdf, max : 500 Kb)</font>
                    </b-th>
                    <b-th >
                      <b-form-file v-model="fileUpload"  v-on:input="uploadData('6')"  plain accept="application/pdf" v-if="lhkpn.verifikasi != 'Valid'"></b-form-file>
                    </b-th>
                    <b-th >
                      <b-button v-if="lhkpn.file != '' && lhkpn.file != null " type="button" variant="primary" class="mt-4" @click="lihat(6)">Lihat Data</b-button>
                    </b-th>
                    <b-th >
                      {{ lhkpn.verifikasi }}
                    </b-th>
                    <b-th >
                      {{ lhkpn.keterangan }}
                    </b-th>
                  </b-tr>
                  <b-tr >
                    <b-th >7</b-th>
                    <b-th >
                      Tanda bukti penyerahan Surat Pemberitahuan Tahunan (SPT) Pajak tahun 2020 dan 2021 <font color="red">(tipe file : pdf/jpeg/jpg, max : 500 Kb)</font> Contoh SPT : 
                      <!-- <a href="#"  v-on:click="downloadSPT()">Donwload SPT</a> -->
                    </b-th>
                    <b-th >
                      <b-form-file v-model="fileUpload"  v-on:input="uploadData('7')"  plain accept="image/jpeg, image/jpg, application/pdf" v-if="tanda_bukti_spt.verifikasi != 'Valid'"></b-form-file>
                    </b-th>
                    <b-th >
                      <b-button v-if="tanda_bukti_spt.file != '' && tanda_bukti_spt.file != null " type="button" variant="primary" class="mt-4" @click="lihat(7)">Lihat Data</b-button>
                    </b-th>
                    <b-th >
                      {{ tanda_bukti_spt.verifikasi }}
                    </b-th>
                    <b-th >
                      {{ tanda_bukti_spt.keterangan }}
                    </b-th>
                  </b-tr>
                  <b-tr >
                    <b-th >8</b-th>
                    <b-th >
                      Surat persetujuan/rekomendasi untuk melamar dari <b>Pejabat Pembina Kepegawaian atau Pejabat lain yang diberikan mandat/kewenangan </b><font color="red">(tipe file : pdf, max : 500 Kb)</font>
                    </b-th>
                    <b-th >
                      <b-form-file v-model="fileUpload"  v-on:input="uploadData('8')"  plain accept="application/pdf" v-if="surat_rekomendasi.verifikasi != 'Valid'"></b-form-file>
                    </b-th>
                    <b-th >
                      <b-button v-if="surat_rekomendasi.file != '' && surat_rekomendasi.file != null " type="button" variant="primary" class="mt-4" @click="lihat(8)">Lihat Data</b-button>
                    </b-th>
                    <b-th >
                      {{ surat_rekomendasi.verifikasi }}
                    </b-th>
                    <b-th >
                      {{ surat_rekomendasi.keterangan }}
                    </b-th>
                  </b-tr>
                  <b-tr >
                    <b-th >9</b-th>
                    <b-th >
                      Surat pernyataan tidak sedang dalam proses pemeriksaan pelanggaran disiplin dan/atau tidak pernah menjalani hukuman disiplin sesuai dengan ketentuan peraturan perundang-undangan yang berlaku selama 2 (dua) tahun terakhir, dan tidak sedang dalam proses peradilan pidana dari <b> Pejabat Pembina Kepegawaian atau Pejabat Pimpinan Tinggi Pratama yang membidangi kepegawaian </b><font color="red">tipe file : pdf, max : 500 Kb)</font>
                    </b-th>
                    <b-th >
                      <b-form-file v-model="fileUpload"  v-on:input="uploadData('9')"  plain accept="application/pdf" v-if="surat_pernyataan_tidak_sedang_hukdis.verifikasi != 'Valid'"></b-form-file>
                    </b-th>
                    <b-th >
                      <b-button v-if="surat_pernyataan_tidak_sedang_hukdis.file != '' && surat_pernyataan_tidak_sedang_hukdis.file != null " type="button" variant="primary" class="mt-4" @click="lihat(9)">Lihat Data</b-button>
                    </b-th>
                    <b-th >
                      {{ surat_pernyataan_tidak_sedang_hukdis.verifikasi }}
                    </b-th>
                    <b-th >
                      {{ surat_pernyataan_tidak_sedang_hukdis.keterangan }}
                    </b-th>
                  </b-tr>
                  <b-tr >
                    <b-th >10</b-th>
                    <b-th >
                      Sertifikat Diklat Kepemimpinan/Diklat Fungsional dan Diklat Teknis yang sesuai dengan jabatan yang akan dilamar  <font color="red">(tipe file : pdf, max : 6000 Kb)</font>
                    </b-th>
                    <b-th >
                      <b-form-file v-model="fileUpload"  v-on:input="uploadData('10')"  plain accept="application/pdf" v-if="sertifikasi_pelatihan.verifikasi != 'Valid'"></b-form-file>
                    </b-th>
                    <b-th >
                      <b-button v-if="sertifikasi_pelatihan.file != '' && sertifikasi_pelatihan.file != null " type="button" variant="primary" class="mt-4" @click="lihat(10)">Lihat Data</b-button>
                    </b-th>
                    <b-th >
                      {{ sertifikasi_pelatihan.verifikasi }}
                    </b-th>
                    <b-th >
                      {{ sertifikasi_pelatihan.keterangan }}
                    </b-th>
                  </b-tr>
                  <b-tr >
                    <b-th >11</b-th>
                    <b-th >
                      Daftar riwayat hidup  <font color="red">(tipe file : pdf, max : 1500 Kb)</font>
                    </b-th>
                    <b-th >
                      <b-form-file v-model="fileUpload"  v-on:input="uploadData('11')"  plain accept="application/pdf" v-if="formulir_drh.verifikasi != 'Valid'"></b-form-file>
                    </b-th>
                    <b-th >
                      <b-button v-if="formulir_drh.file != '' && formulir_drh.file != null " type="button" variant="primary" class="mt-4" @click="lihat(11)">Lihat Data</b-button>
                    </b-th>
                    <b-th >
                      {{ formulir_drh.verifikasi }}
                    </b-th>
                    <b-th >
                      {{ formulir_drh.keterangan }}
                    </b-th>
                  </b-tr>

                </b-tbody>
              </b-table-simple>
            </b-col>
          </b-row>
         
		    </b-overlay>
      </div>
    </div>
    <modal></modal>
  </div>
</template>
<script>
  import Modal from './Modal.vue'
  import { list_upload_dokumen_peserta, viewPdfPegawai, downloadSPT } from "../../../api/peserta"
  import request from '../../../util/request';
  export default{
    components: {
      Modal
    },
    data () {
      return {
        fileUpload: null,
        isLoad: false,
        modalShow: false,
        percent: 0,
        viewPdf: '',
        error: '',
        suratLamaran: {
          file: '',
          verifikasi: '',
          keterangan: ''
        },
        ijazah: {
          file: '',
          verifikasi: '',
          keterangan: ''
        },
        sk_pengangkatan_akhir: {
          file: '',
          verifikasi: '',
          keterangan: ''
        },
        suker_rekam_jejak: {
          file: '',
          verifikasi: '',
          keterangan: ''
        },
        penilaian_prestasi: {
          file: '',
          verifikasi: '',
          keterangan: ''
        },
        lhkpn: {
          file: '',
          verifikasi: '',
          keterangan: ''
        },
        tanda_bukti_spt: {
          file: '',
          verifikasi: '',
          keterangan: ''
        },
        surat_rekomendasi: {
          file: '',
          verifikasi: '',
          keterangan: ''
        },
        surat_pernyataan_tidak_sedang_hukdis: {
          file: '',
          verifikasi: '',
          keterangan: ''
        },
        sertifikasi_pelatihan: {
          file: '',
          verifikasi: '',
          keterangan: ''
        },
        formulir_drh: {
          file: '',
          verifikasi: '',
          keterangan: ''
        }
        
      }
    },
    created() {
      this.showData();
    },
    methods: {
      showData(){
        this.isLoad = true
        list_upload_dokumen_peserta(this.data)
        .then((response) => {
          this.isLoad = false;
          this.suratLamaran.file = response[0].file;
          this.suratLamaran.verifikasi = response[0].verifikasi;
          this.suratLamaran.keterangan = response[0].keterangan;

          this.ijazah.file = response[1].file;
          this.ijazah.verifikasi = response[1].verifikasi;
          this.ijazah.keterangan = response[1].keterangan;

          this.sk_pengangkatan_akhir.file = response[2].file;
          this.sk_pengangkatan_akhir.verifikasi = response[2].verifikasi;
          this.sk_pengangkatan_akhir.keterangan = response[2].keterangan;

          this.suker_rekam_jejak.file = response[3].file;
          this.suker_rekam_jejak.verifikasi = response[3].verifikasi;
          this.suker_rekam_jejak.keterangan = response[3].keterangan;

          this.penilaian_prestasi.file = response[4].file;
          this.penilaian_prestasi.verifikasi = response[4].verifikasi;
          this.penilaian_prestasi.keterangan = response[4].keterangan;

          this.lhkpn.file = response[5].file;
          this.lhkpn.verifikasi = response[5].verifikasi;
          this.lhkpn.keterangan = response[5].keterangan;

          this.tanda_bukti_spt.file = response[6].file;
          this.tanda_bukti_spt.verifikasi = response[6].verifikasi;
          this.tanda_bukti_spt.keterangan = response[6].keterangan;

          this.surat_rekomendasi.file = response[7].file;
          this.surat_rekomendasi.verifikasi = response[7].verifikasi;
          this.surat_rekomendasi.keterangan = response[7].keterangan;

          this.surat_pernyataan_tidak_sedang_hukdis.file = response[8].file;
          this.surat_pernyataan_tidak_sedang_hukdis.verifikasi = response[8].verifikasi;
          this.surat_pernyataan_tidak_sedang_hukdis.keterangan = response[8].keterangan;

          this.sertifikasi_pelatihan.file = response[9].file;
          this.sertifikasi_pelatihan.verifikasi = response[9].verifikasi;
          this.sertifikasi_pelatihan.keterangan = response[9].keterangan;

          this.formulir_drh.file = response[10].file;
          this.formulir_drh.verifikasi = response[10].verifikasi;
          this.formulir_drh.keterangan = response[10].keterangan;
        }).catch((error) => {
          this.isLoad = false;
          console.log(error)
        });
      },  
      uploadData(tipe){      
        if(this.fileUpload != null){
          this.isLoad = true;
          let formData = new FormData();
          var size = 0;
          var nilaiError = 0;
          if(tipe == '10'){
            size = (1024 * 1024) *6
          } else if (tipe == '11' || tipe == '5'){
            size = (1024 * 1024) *1.5
          } else {
            size = 511626;
          }
          if(tipe != '7'){
            if(this.fileUpload.type != 'application/pdf'){
              nilaiError = 1;
            }
          } else {
            if(this.fileUpload.type != 'application/pdf' && this.fileUpload.type != 'image/jpeg'){
              nilaiError = 1;
            }
          }
          if(nilaiError > 0 ){
            this.isLoad = false;
            this.$notify({
              icon: "nc-icon nc-bell-55",
              message: "Tipe file tidak sesuai",
              type: "danger",
            });
            this.fileUpload = null;
          } else if (this.fileUpload.size > size) {
            this.isLoad = false;
            this.$notify({
              icon: "nc-icon nc-bell-55",
              message: "Size file lebih besar dari ketentuan, silahkan diperkecil terlebih dahulu",
              type: "danger",
            });
            this.fileUpload = null;
          } else {
            formData.append('fileUpload',this.fileUpload);
            formData.append('tipe',tipe);
            this.$store.dispatch('uploadDokumen', formData)
              .then(response => {
                this.fileUpload = null;
                this.showData()
                this.isLoad = false;
              }).catch( e => {
                this.isLoad = false;
                this.fileUpload = null;
                console.log(error)
              });  
          }
        } 

      },
      lihat(data){
        const params = { tipe : data}
        viewPdfPegawai(params)
          .then(response => {
            const file = new Blob(
            [response],
            { type: 'application/pdf' })
            // Build a URL from the file
            const fileURL = URL.createObjectURL(file)
            // Open the URL on new Window
            window.open(fileURL, '_blank')		
          })
          .catch(error => {
            this.$notify('error',"Error", error, { duration: 3000, permanent: false });
            // console.log(error)
          })
		  },
      downloadSPT(){
        downloadSPT()
          .then(response => {
            const file = new Blob(
            [response],
            { type: 'image/jpeg' })
            // Build a URL from the file
            const fileURL = URL.createObjectURL(file)
            // Open the URL on new Window
            window.open(fileURL, '_blank')		
          })
          .catch(error => {
            this.$notify('error',"Error", error, { duration: 3000, permanent: false });
            // console.log(error)
          })
		  },
    }
  }
</script>
