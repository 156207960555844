<template>

<modal :show.sync="modalShow" headerClasses="justify-content-center">
  <h4 slot="header" class="title title-up">Upload Progress</h4>
      <progress-dokumen />
      <template slot="footer">
       <p>Harap Bersabar :)</p>
      </template>
      </modal>
  
</template>

<script>
import {Modal} from 'src/components/UIComponents'
import ProgressDokumen from './Progress.vue'
import { mapGetters } from 'vuex';
  export default {
    components: {
      ProgressDokumen,
      Modal
    },
    computed: {
    ...mapGetters(['modalShow'])
  }
  }
</script>