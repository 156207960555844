<template>
  <div>
    <b-progress :max="max" height="2rem">
      <b-progress-bar :value="percentDokumen">
        <span><strong>{{ value.toFixed(2) }} / {{ max }}</strong></span>
      </b-progress-bar>
    </b-progress>
  </div>
</template>

<script>
 import PProgress from 'src/components/UIComponents/Progress.vue'
import { mapGetters } from 'vuex';
export default {
  components: {
    PProgress
  },
  computed: {
    ...mapGetters(['percentDokumen']),
  },
  data() {
    return {
      max: 100,
      value: 100
    }
  },
}
</script>

<style>

</style>