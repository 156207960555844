<template>
  <div class="container">
    <b-breadcrumb :items="items"></b-breadcrumb>
    
    <b-card >
    <b-card-text>
      <h1>Pengumuman</h1>
    </b-card-text>
    <b-container class="bv-example-row" fluid>
      <b-row class="text-center">
        <b-col>  
          <b-button type="button" variant="primary" block round @click="lihat('6')">Download Pengumuman Hasil Seleksi Administrasi Selter JPTP 2022</b-button>
        </b-col>
      </b-row>
    </b-container>
    <img src="/img/pengumuman.jpg" alt="DE" />
  </b-card>
   
  </div>
</template>
<script>
import { viewDokumen } from '../../api/user'
export default{
  data() {
    return {
      items: [
          {
            text: 'Beranda',
            href: '#'
          },
          {
            text: 'Pengumuman',
            active: true
          }
        ]
    }
  },
  methods: {
    lihat(id){
        const params = { tipe : id}
        viewDokumen(params)
          .then(response => {
            const file = new Blob(
            [response],
            { type: 'application/pdf' })
            // Build a URL from the file
            const fileURL = URL.createObjectURL(file)
            // Open the URL on new Window
            window.open(fileURL, '_blank')		
          })
          .catch(error => {
            this.$notify('error',"Error", error, { duration: 3000, permanent: false });
            // console.log(error)
          })
		  },
  }
}
</script>