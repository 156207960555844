<template>
  <div>
    <div class="header">
      <div class="container">
        <div class="header-navigation pull-right font-transform-inherit">
          &nbsp;
        </div>
      </div>
    </div>
    <b-navbar toggleable="lg" type="light">
      <b-navbar-brand href="#">
        <img src="/static/img/logo_bkn.jpg" alt="DE" />
      </b-navbar-brand>

      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav> </b-navbar-nav>

        <!-- Right aligned nav items -->
        <b-navbar-nav class="ml-auto">
          <b-navbar-brand tag="h2" class="mb-0" right
            ><router-link to="/"  class="link-dark">Beranda</router-link></b-navbar-brand
          >
          <b-navbar-brand tag="h1" class="mb-0" right
            ><router-link to="/pengumuman"  class="link-dark">Pengumuman</router-link>
            </b-navbar-brand
          >
          <b-navbar-brand tag="h1" class="mb-0" right
            ><router-link to="/persyaratan"  class="link-dark">Persyaratan</router-link></b-navbar-brand
          >
          <b-navbar-brand
            tag="h1"
            class="mb-0"
            right
            @click="go('DokumenPersyaratan')"
            ><router-link to="/persyaratan"  class="link-dark">Dokumen Persyaratan</router-link></b-navbar-brand
          >
          <b-navbar-brand tag="h1" class="mb-0" right
            ><router-link to="/alur"  class="link-dark">Alur</router-link>
            </b-navbar-brand
          >
          <b-navbar-brand tag="h1" class="mb-0" right
            ><router-link to="/jadwal"  class="link-dark">Jadwal Seleksi</router-link></b-navbar-brand
          >
                    <b-navbar-brand tag="h1" class="mb-0" right
            ><router-link to="/login"  class="link-dark">Login</router-link></b-navbar-brand
          >
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
    <router-view />
  </div>
</template>
<script>
export default {
  data() {
    return {
      link: {
        path: '/login',
        name: 'Login',

      }
    }
  },
  methods: {
    go(link) {
      this.$router.push({ name: link });
    },
  },
};
</script>
