<template>
  <div class="container">
    <b-breadcrumb :items="items"></b-breadcrumb>
    
    <b-card >
    <b-card-text>
      <h1>Alur </h1>
    </b-card-text>
    <img src="/img/alur.png" alt="DE" />
  </b-card>
   
  </div>
</template>
<script>
export default{
  data() {
    return {
      items: [
          {
            text: 'Beranda',
            href: '#'
          },
          {
            text: 'Alur Seleksi',
            active: true
          }
        ]
    }
  },
}
</script>