import request from '../util/request';
export function list_upload_dokumen_peserta() {
  return request({
    url: 'auth/listUploadDokumenPeserta',
    method: 'get'
  });
}

export function viewPdfPegawai(data) {
  return request({
    url: 'auth/viewPdfPegawai',
    method: 'get',
    params: data,
    responseType: 'blob'
  });
}

export function saveUploadDokumen(query) {
  return request({
    url: 'auth/uploadDokumen',
    method: 'post',
    data : query
  });
}

export function downloadSPT() {
  return request({
    url: 'auth/downloadSPT',
    method: 'get',
    responseType: 'blob'
  });
}
